/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 12/04/2021.
 */
import React from 'react'
import { SectionTitle } from './SectionTitle'

const AboutUs = ({ gotoPatientEngagement, gotoWhyGoDigital, gotoOurServices }) => {
  return (
    <div className="container-fluid" style={{ background: '#F3FAF5' }}>
      <div className="container-fluid content-container section-margin ">
        <div className="row ">
          <SectionTitle title="About us"></SectionTitle>
          <div className="col-12 col-md-6 mb-4">
            <h3>
              Setting new standards in
              <br />
              clinical research
            </h3>
          </div>

          <div className="col-12 col-md-6  ">
            <p>
              As Europe's first virtual contract research organisation, we're on a mission to transform the world of
              clinical research. By{' '}
              <u className="in-text-link" onClick={gotoWhyGoDigital}>
                digitalizing
              </u>{' '}
              all stages of the study, and{' '}
              <u className="in-text-link" onClick={gotoPatientEngagement}>
                actively engaging patients
              </u>{' '}
              as our design partners, we are able to create{' '}
              <u className="in-text-link" onClick={gotoOurServices}>
                end-to-end experiences
              </u>{' '}
              that reach previously excluded patient populations, and deliver superior outcomes for participants,
              investigators and sponsors alike.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutUs
