/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 12/04/2021.
 */
import React from 'react'
import { SectionTitle } from './SectionTitle'
import { BigSectionTitle } from './BigSectionTitle'
import FadeUp from '../animation/FadeUp'

import Diabetes2Digital from '../assets/apotekerforeningen_Type_2_Diabetes_Sept_2020.pdf'
import genome from '../assets/Pressemeddelelse_Geno-ME Studiet_20201118.pdf'
import COAKS from '../assets/Studies&Me_Collaboration_with_Coegin_Pharma AB_26.01.2021.pdf'

const items = [
  {
    title: 'Diabetes2Digital',
    body:
      'Studies&Me has finalized a decentralized pilot study to test the data quality of blood sugar and behaviometric monitoring of type 2 diabetes patients in collaboration with Glostrup Pharmacy.',
    linkTxt: 'Download press release (PDF)',
    link: Diabetes2Digital,
  },
  {
    title: 'Geno-ME',
    body:
      'Studies&Me is conducting a decentralized clinical study in collaboration with Bispebjerg Hospital to investigate how genetics affect Atopic Dermatitis (AD).',
    linkTxt: 'Download press release, in Danish (PDF)',
    link: genome,
  },
  {
    title: 'COAKS',
    body:
      'Studies&Me is conducting a combined phase 1/2a interventional study for Coegin Pharma’s topical AVX001 in the treatment of precancerous skin lesions, Actinic Keratosis.',
    link: COAKS,
    linkTxt: 'Download press release (PDF)',
  },
]

export const OurStudies = (props) => {
  return (
    <div className="container-fluid " style={{ background: '#121533' }}>
      <div className="container-fluid content-container section-margin">
        <div className="row ">
          <FadeUp delay={0} className="col-6 ">
            <BigSectionTitle label="Our studies" className="text-white mb-7 mt-6 "></BigSectionTitle>
          </FadeUp>
          <div className="col-6"></div>

          {items.map((item, index) => {
            const d = '0.' + (index + 1)
            return (
              <FadeUp delay={d} key={index} className="col-12 col-md-4 mb-6">
                <h6 className="text-white mb-4">{item.title}</h6>

                <p className="mb-5 text-white pr-0 pr-md-6">{item.body}</p>
                <p className="text-white">
                  <a className="text-white" href={item.link} target="_blank">
                    <u>{item.linkTxt}</u>
                  </a>
                </p>
              </FadeUp>
            )
          })}
        </div>
      </div>
    </div>
  )
}
