/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 12/04/2021.
 */
import React from 'react'
import { SectionTitle } from './SectionTitle'
import FadeUp from '../animation/FadeUp'

export const OurVision = (props) => {
  return (
    <div className="container-fluid content-container section-margin">
      <div className="container-fluid ">
        <FadeUp className="row  ">
          <SectionTitle title="Our Vision"></SectionTitle>

          <div className="col-12 col-md-6 mb-4 ">
            <h3 className="pr-6">
              The right treatment,
              <br />
              at the right time.
            </h3>
          </div>

          <div className="col-12 col-md-6">
            <p>
              Delivering superior clinical studies that put patients first is the heart of our business today. But what
              about the future? Two words: Personalized treatment. This exciting new approach for disease treatment uses
              a patient centric-approach to treatment development that takes into account a person's individual
              variability in environment and lifestyle, along with their genetic and physiological makeup. The result? A
              new era of medicine – one that delivers the right treatment, at the right time.
            </p>
          </div>
        </FadeUp>
      </div>
    </div>
  )
}
