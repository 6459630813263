import React, { useRef } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { withBreakpoints } from 'react-breakpoints'
import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/all'

import ArticleSection from '../components/articles/ArticleSection'
import AboutUs from '../components/AboutUs'

import { OurVision } from '../components/OurVision'
import { OurServices } from '../components/OurServices'
import { OurStudies } from '../components/OurStudies'
import ImageHeader from '../components/ImageHeader'
import { WhyGoDigital } from '../components/WhyGoDigital'
//import FeaturedStory from '../components/featured-story/FeaturedStory'
//import { PatientEngagement } from '../components/PatientEngagement'
import pressReleasePdf from '../assets/S&Me_press_release.pdf'

import { getElementYOffset } from '../utils/elementOffset'
import { ParticipantsSay } from '../components/ParticipantsSay'
//import WhatWeGiveYou from '../components/WhatWeGiveYou'
import loadable from '@loadable/component'
//import { WeCollabroate } from '../components/WeCollabroate'

const PatientEngagement = loadable(() => import('../components/PatientEngagement'))
const FeaturedStory = loadable(() => import('../components/featured-story/FeaturedStory'))
const PressRelease = loadable(() => import('../components/PressRelease'))
const WhatWeGiveYou = loadable(() => import('../components/WhatWeGiveYou'))
const WeCollabroate = loadable(() => import('../components/WeCollabroate'))

gsap.registerPlugin(ScrollToPlugin)

const sliderTime = 3000

const IndexPage = (props) => {
  const { data, breakpoints, currentBreakpoint } = props
  const colabRef = useRef()
  const patientEngagementRef = useRef()
  const goDigitalRef = useRef()
  const ourServicesRef = useRef()

  const isMobile = breakpoints[currentBreakpoint] > breakpoints.sm ? false : true

  const toColab = () => {
    const ypos = getElementYOffset(colabRef)
    gsap.to(window, { duration: 1, scrollTo: { y: ypos, autoKill: true }, ease: 'Strong.easeOut' })
  }

  const gotoPatientEngagement = () => {
    const ypos = getElementYOffset(patientEngagementRef)
    gsap.to(window, { duration: 1, scrollTo: { y: ypos, autoKill: true }, ease: 'Strong.easeOut' })
  }
  const gotoWhyGoDigital = () => {
    const ypos = getElementYOffset(goDigitalRef)
    gsap.to(window, { duration: 1, scrollTo: { y: ypos, autoKill: true }, ease: 'Strong.easeOut' })
  }

  const gotoOurServices = () => {
    const ypos = getElementYOffset(ourServicesRef)
    gsap.to(window, { duration: 1, scrollTo: { y: ypos, autoKill: true }, ease: 'Strong.easeOut' })
  }

  return (
    <Layout>
      <SEO title="Studies&me" />
      <ImageHeader data={data} toColab={toColab}></ImageHeader>
      <AboutUs
        gotoWhyGoDigital={gotoWhyGoDigital}
        gotoPatientEngagement={gotoPatientEngagement}
        gotoOurServices={gotoOurServices}
      ></AboutUs>
      <div>
        <WhatWeGiveYou></WhatWeGiveYou>
      </div>

      {/*<OurClients></OurClients>*/}
      <div ref={colabRef} className="container-fluid " style={{ background: '#FBFAF9' }}>
        <WeCollabroate></WeCollabroate>
      </div>
      <OurVision></OurVision>
      <div ref={ourServicesRef} className="container-fluid " style={{ background: '#F5F6FD' }}>
        <OurServices isMobile={isMobile}></OurServices>
      </div>
      <OurStudies></OurStudies>
      <div ref={goDigitalRef} className="container-fluid mt-7 " style={{}}>
        <WhyGoDigital></WhyGoDigital>
      </div>

      <div ref={patientEngagementRef} className="container-fluid" style={{ background: '#FEF7F3' }}>
        <PatientEngagement isMobile={isMobile}></PatientEngagement>
      </div>
      <ParticipantsSay></ParticipantsSay>
      <FeaturedStory></FeaturedStory>
      {/*<ArticleSection title="Recent News" data={data.newsLinks.edges} bgColor={'#F3FAF5'}></ArticleSection>*/}
      {/*<ArticleSection title="Our Publications" data={data.pubLinks.edges} bgColor={'#EBF7EF'}></ArticleSection>*/}
      <PressRelease></PressRelease>
      {/*<ArticleSection title="Recent News" data={data.newsLinks.edges} bgColor={'#F3FAF5'}></ArticleSection>
      <ArticleSection title="Our Publications" data={data.pubLinks.edges} bgColor={'#EBF7EF'}></ArticleSection>*/}
    </Layout>
  )
}

/*
export const query = graphql`
  {
    pressRelease: allImageSharp(filter: { fluid: { originalName: { regex: "/pressRelease/" } } }) {
      edges {
        node {
          fluid(webpQuality: 80, maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            aspectRatio
            originalName
          }
        }
      }
    }

    newsLinks: allStrapiNewsLink(
      sort: { fields: created_at, order: DESC }
      filter: { featured: { eq: false }, publish: { eq: true } }
    ) {
      edges {
        node {
          title
          link
          origin
          featured
          created_at
        }
      }
    }

    pubLinks: allStrapiPublicationLink(sort: { fields: created_at, order: DESC }, filter: { featured: { eq: false } }) {
      edges {
        node {
          title
          link
          origin
          featured
          created_at
        }
      }
    }
  }
`
*/

/*query MyQuery($fields1: [StrapiNewsLinkFieldsEnum] = created_at) {*/
//allStrapiNewsLink(sort: {fields: $fields1, order: DESC}, filter: {featured: {eq: false}}) {

export default withBreakpoints(IndexPage)
