/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 04/01/2021.
 */
import React, { useRef, useState } from 'react'

import { ArticleLink } from '../frontpage/ArticleLink'
import arrowSvg from '../../assets/arrowBlack.svg'
import HozScroller from '../display/HozScoller'

const ArticleSection = ({ data, title, bgColor }) => {
  const [slideIndex, setSlideIndex] = useState(0)
  const arrorRight = useRef()
  const arrorLeft = useRef()

  if (data.length === 0) {
    return null
  }

  const prevClick = () => {
    const newValue = slideIndex - 1 < 0 ? data.length - 1 : slideIndex - 1
    setSlideIndex(newValue)
  }

  const nextClick = () => {
    const newValue = slideIndex + 1 > data.length - 1 ? 0 : slideIndex + 1
    setSlideIndex(newValue)
  }

  const indexChange = (value) => {
    console.log(' ArticleSection > value = ', value)
    setSlideIndex(value)
  }

  return (
    <div className="container-fluid overflow-hidden" style={{ background: bgColor }}>
      <div className="section-margin">
        <div className="container-fluid content-container ">
          <div className="row mb-0 mb-md-4">
            <div className="col-12 col-md-6  mb-0 mb-md-5 ">
              <p className="p-large-bold">{title}</p>
            </div>
            <div className="col-6 d-flex justify-content-end pt-3">
              <img
                onClick={prevClick}
                className="mr-3 pointer d-none d-md-block"
                ref={arrorLeft}
                style={{ width: 'auto', height: '2rem', transform: 'rotate(180deg)' }}
                src={arrowSvg}
                alt=""
              />
              <img
                onClick={nextClick}
                ref={arrorRight}
                className="pointer d-none d-md-block"
                style={{ width: 'auto', height: '2rem' }}
                src={arrowSvg}
                alt=""
              />
            </div>
          </div>
        </div>

        <div className="container-fluid content-container ">
          <HozScroller
            className="row mt-2 mt-md-5 flex-nowrap noselect "
            slideIndex={slideIndex}
            indexChange={indexChange}
            useSnapping={true}
          >
            {data.map((item, index) => {
              const ref = useRef()
              return <HozItem ref={ref} key={index} item={item}></HozItem>
            })}
          </HozScroller>
        </div>
        <div className="container-fluid content-container ">
          <div className="row" style={{}}>
            <div className="col-12 mt-5 d-flex">
              {data.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="rounded-circle bg-primary mr-2"
                    style={{ width: 10, height: 10, opacity: slideIndex === index ? 1 : 0.2 }}
                  ></div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const HozItem = React.forwardRef(({ item }, ref) => {
  return (
    <div ref={ref} className="col-11 col-md-4">
      <ArticleLink link={item.node.link} linkTxt={item.node.origin} text={item.node.title} />
    </div>
  )
})

export default ArticleSection
