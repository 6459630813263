/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 12/04/2021.
 */

import React, { useState } from 'react'
import { SectionTitle } from './SectionTitle'
import checkmark from '../assets/vision-checkmark.svg'
import FadeUp from '../animation/FadeUp'
import plusIcon from '../assets/plus-icon.svg'
const rawItems = [
  {
    title: 'Tailored mobile app design',
  },
  {
    title: 'Project management',
  },
  {
    title: 'Protocol writing & submission',
  },
  {
    title: 'Patient research & recruitment',
  },
  {
    title: 'Investigators & sub-investigator sourcing',
  },
  {
    title: 'On-site and remote pre-screening',
  },
  {
    title: 'Patient support in-trial',
  },
  {
    title: 'eConsent & ePRO',
  },
  {
    title: 'Data management',
  },
  {
    title: 'Reporting & publishing',
  },
  {
    title: 'Complete regulatory compliance',
  },
  {
    title: 'User experience design',
  },
]

const checkmarkStyles = { left: 8, top: 8 }

export const OurServices = ({ isMobile }) => {
  const [count, setCount] = useState(4)
  const items = isMobile ? rawItems.slice(0, count) : rawItems

  const showMore = () => {
    setCount(rawItems.length)
  }

  return (
    <div className="container-fluid content-container section-margin ">
      <div className="row" style={{}}>
        <SectionTitle title="Our Services"></SectionTitle>
      </div>
      <FadeUp className="row">
        <div className="col-12 col-md-6 mt-4 mt-md-0 mb-md-6 mb-4">
          <h4 className="mb-4">Everything you need in one place</h4>
          <p className="">
            Why coordinate between multiple vendors to get one job done? With us, you don't have to. We deliver every
            aspect of your study from start to finish. Our services include:
          </p>
        </div>
      </FadeUp>

      <FadeUp className="row  " style={{}}>
        {items.map((item, index) => {
          return (
            <div key={index} className="col-12 col-md-2 mb-4 mb-md-7">
              <div
                className="rounded-circle position-relative mb-3 mb-md-4"
                style={{ width: 36, height: 36, background: '#304CD0' }}
              >
                <img className="position-absolute" style={checkmarkStyles} src={checkmark} alt="check" />
              </div>
              <h5 className="h5-small">{item.title}</h5>
            </div>
          )
        })}
      </FadeUp>
      {count !== rawItems.length ? (
        <FadeUp className="row d-block d-md-none " style={{}}>
          <div className="col-12 d-flex">
            <img src={plusIcon} alt="" />
            <p className="ml-2 mt-3 p-large-bold" onClick={showMore}>
              <u>See more</u>
            </p>
          </div>
        </FadeUp>
      ) : null}
    </div>
  )
}
