/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 16/04/2021.
 */
import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import './image-header.scss'
import logo from '../assets/sandme-logo-white.svg'
import { LINK_TO_PARTICIPATE } from '../constant'

const ImageHeader = ({ data, toColab }) => {
  return (
    <div className="position-relative image-header mt-md-6">
      <div className="container-fluid position-absolute h-100 ">
        <div className="row h-100" style={{}}>
          <div className="col-12 h-100 px-0 ">
            <StaticImage
              className="h-100 w-100"
              objectFit="cover"
              src="../images/header_001.png"
              objectPosition="50% 50%"
              alt="studies and me"
              width={1600}
              quality={100}
            ></StaticImage>
          </div>
        </div>
      </div>

      <div className="frontpage container-fluid content-container h-100">
        <div className="row h-100" style={{}}>
          <div className="col-12 col-lg-10 col-xl-7 h-100 d-flex flex-column justify-content-between">
            <div>
              <img src={logo} className="mt-4 d-block d-md-none" style={{ height: '1.4rem' }} alt="" />
            </div>

            <div className="">
              <h6 className="text-center text-md-left text-white mb-5">Welcome to Studies&Me</h6>
              <h2 className="text-center text-md-left text-white mb-5">The future of treatment development</h2>
              <div className="mt-5 d-flex flex-md-row flex-column align-content-center">
                <button onClick={toColab} className="btn btn-primary rounded-pill px-4 px-md-6 mb-3 mb-md-0 mr-md-4">
                  Collaborate
                </button>
                {/*  <a href={LINK_TO_PARTICIPATE} className="w-100 " rel="noopener noreferrer">
                  <button className="participateLink btn btn-primary rounded-pill px-4 px-md-6 ">Participate</button>
                </a>*/}
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ImageHeader
