/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 22/04/2021.
 */
import React from 'react'
import LessVisits from '../assets/why-digital/less-visits.svg'
import IncreasedStudy from '../assets/why-digital/increasedStudy.svg'
import Automatic from '../assets/why-digital/automatic.svg'
import RealWorldData from '../assets/why-digital/realWorldData.svg'
import ReducedRecall from '../assets/why-digital/reducedRecall.svg'
import EasierRecruitment from '../assets/why-digital/easierRecruitment.svg'
import PatientEngagement from '../assets/why-digital/patientEngagement.svg'
import DataCollection from '../assets/why-digital/dataCollection.svg'
import Visibility from '../assets/why-digital/visiblity.svg'
import DecreasedBurden from '../assets/why-digital/decreasedBurden.svg'
const items = [
  {
    icon: LessVisits,
    title: 'Less site visits',
  },
  {
    icon: IncreasedStudy,
    title: 'Increased study task compliance',
  },
  {
    icon: Automatic,
    title: 'Automatic randomization',
  },
  {
    icon: RealWorldData,
    title: 'Real world data',
  },
  {
    icon: ReducedRecall,
    title: 'Reduced recall bias',
  },
  {
    icon: EasierRecruitment,
    title: 'Easier recruitment',
  },
  {
    icon: PatientEngagement,
    title: 'Increased patient engagement',
  },
  {
    icon: DataCollection,
    title: 'Faster data collection & processing',
  },
  {
    icon: Visibility,
    title: 'Enhanced visibility for investigators',
  },
  {
    title: 'Decreased patient burden',
    icon: DecreasedBurden,
  },
]

export const WhyGoDigital = (props) => {
  return (
    <div className="container-fluid content-container">
      <div className="row">
        <div className="col-12 col-md-8 col-xl-6 mt-3 mt-md-6 mb-7">
          <h4 className="mb-4">Why go digital?</h4>
          <p className="">
            Virtual and hybrid-virtual trials are more than just a trend for strange and uncertain times. They present
            significant advantages over traditional brick-and-mortar trials, and unique attributes that enhance outcomes
            for sponsors, investigators and patients alike.
          </p>
        </div>
      </div>
      <div className="row " style={{}}>
        {items.map((item, index) => {
          return (
            <div key={index} className="col-12 col-md-2 mb-7">
              <div className="mb-2">
                <img src={item.icon} alt={item.title} />
              </div>
              <p className="h5-small" style={{ lineHeight: 1.2 }}>
                {item.title}
              </p>
            </div>
          )
        })}
      </div>

      <div className="row" style={{}}>
        <div className="col-12 mb-7"></div>
      </div>
    </div>
  )
}
