/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 22-05-2019.
 */

import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'

import { useGesture } from 'react-use-gesture'
import { gsap } from 'gsap'

const HozScroller = ({ children, className, slideIndex, indexChange, useSnapping = false }) => {
  const [boundaries, setBoundaries] = useState({ left: 0 })
  const [dragStart, setDragStart] = useState(0)

  const bind = useGesture(
    {
      onDrag: ({ event, down, offset: [ox], movement: [mx], direction: [xDir], tap }) => {
        gsap.to(measuredRef.current, 0.5, { x: mx, ease: 'expo.out' })
        if (!down) {
          setDragStart(mx)
        }
      },

      onDragEnd: ({ movement: [mx], offset: [ox] }) => {
        getBoundries()

        if (indexChange || useSnapping) {
          const part = children[0].ref.current.getBoundingClientRect().width
          const v = parseInt(Math.round(mx / part))
          const section = Math.min(0, v)
          const to = section * part

          setDragStart(to)
          if (useSnapping) {
            gsap.killTweensOf(measuredRef.current)
            gsap.to(measuredRef.current, 0.5, { x: to, ease: 'expo.out' })
          }

          if (indexChange) {
            indexChange(Math.abs(section))
          }
        }
      },
    },
    {
      drag: {
        bounds: { left: boundaries.left, right: 0, top: 0, bottom: 0 },
        rubberband: true,
        initial: () => {
          return [dragStart, 0]
        },
        //filterTaps: true,
      },
    }
  )

  const getBoundries = () => {
    const rect = children[0].ref.current.getBoundingClientRect()
    const itemWidth = rect.width
    const k = itemWidth * (children.length * -1)
    const total = k + itemWidth

    setBoundaries({ left: total, x: rect.x })
  }

  useEffect(() => {
    const k = children[0].ref.current.getBoundingClientRect().width * (slideIndex * -1)
    gsap.to(measuredRef.current, 0.5, { x: k, ease: 'expo.out' })
    setDragStart(k)
  }, [slideIndex])

  useLayoutEffect(() => {
    getBoundries()
  }, [])

  const measuredRef = useRef()

  return (
    <div {...bind()} ref={measuredRef} className={className} style={{ touchAction: 'pan-y' }}>
      {children}
    </div>
  )
}

export default HozScroller
