/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 12/04/2021.
 */
import React from 'react'

export const SectionTitle = ({ title = 'title' }) => {
  return (
    <div className="col-12 mb-md-6">
      <p className="p-large-bold">{title}</p>
    </div>
  )
}
