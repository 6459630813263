/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 26/04/2021.
 */

import React from 'react'

import { BigSectionTitle } from './BigSectionTitle'
import FadeUp from '../animation/FadeUp'
import userIcon from '../assets/user.svg'
const items = [
  {
    body:
      '"If you came to me and said, “you want to start over?”, I would say “Very much. With pleasure!” I\'m sorry I was not allowed to attend for a longer time."',
  },
  {
    body: '"The best part for me was getting to know more about my own illness. This study made me open my eyes"',
  },
  {
    body:
      '"This is the very first time I have been in a virtual study. It has been quite an experience. I just had to get used to it, but it has actually worked very well."',
  },
]

export const ParticipantsSay = (props) => {
  return (
    <div className="container-fluid " style={{ background: '#121533' }}>
      <div className="container-fluid content-container section-margin">
        <div className="row ">
          <FadeUp delay={0} className="col-12 ">
            <BigSectionTitle
              label="What our participants say"
              className="text-white mb-md-7 mb-6 mt-6 "
            ></BigSectionTitle>
          </FadeUp>

          {items.map((item, index) => {
            const d = '0.' + (index + 1)
            return (
              <FadeUp delay={d} key={index} className="col-12 col-md-4 mb-3 mb-md-6">
                <img className="mb-4" src={userIcon} alt="user icon" />
                <h6 className="mb-5 text-white pr-0 pr-md-6 h6-quote">
                  <i>{item.body}</i>
                </h6>
              </FadeUp>
            )
          })}
        </div>
      </div>
    </div>
  )
}
